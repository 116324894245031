import React from 'react';
import { connect } from 'react-redux';

import { calculateRendement } from '../../modules/calculations';

import Page from '../../containers/Page';

import Header from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Block, ColumnBlocks, GridBlock } from '../../components/Section';
import SdeCalculator from '../../containers/SdeCalculator/SdeCalculator';
import { SubmitButton } from '../../components/Buttons/Buttons';
import LandGezocht from '../../containers/LandGezocht/LandGezocht';

import { initStateContent } from '../ContentPage/content';

import stylesForm from '../../componentStyles/component.scss';

class HomePage extends React.Component {
  state = { ...initStateContent(this.props) };

  fetchData = () =>
    new Promise((resolve, reject) => {
      // simulate fetching data
      // setTimeout(() => {
      return resolve(true);
      // }, 10000);
    });

  constructor(props) {
    super(props);
    this.handleCalculationSubmit = this.handleCalculationSubmit.bind(this);
  }

  handleCalculationSubmit(event) {
    event.preventDefault();
    this.props.history.push('/calculator');
  }

  render() {
    return (
      <Page id="homepage">
        <Header />
        <Block
          icon={this.state.blocks.icon}
          classes={'section--background--skewed'}
          {...this.state.blocks.introduction}
        >
          {this.state.blocks.introduction.content}
        </Block>
        <Block
          icon={this.state.blocks.icon}
          classes={'section--background--skewed--customBackground'}
          {...this.state.blocks.dakContent}
        >
          {this.state.blocks.dakContent.content}
        </Block>
        <ColumnBlocks
          title={this.state.rendementsBerekening.title}
          intro={this.state.rendementsBerekening.intro}
          icon={this.state.rendementsBerekening.icon}
          columns={this.state.rendementsBerekening}
          classes={'section__container__columns section--clear-diagonal'}
          options={{ renderChildrenOnly: true }}
        >
          <GridBlock>
            <form
              onSubmit={this.handleCalculationSubmit}
              className={stylesForm.component__form}
            >
              <div className={stylesForm.component__form__wrapper}>
                <SdeCalculator />
              </div>
              <SubmitButton
                className={stylesForm.component__form__wrapper__submitBtn}
                text="Bereken rendement"
              />
            </form>
          </GridBlock>
        </ColumnBlocks>
        <ColumnBlocks
          title={this.state.landGezocht.title}
          intro={this.state.landGezocht.intro}
          icon={this.state.landGezocht.icon}
          columns={this.state.landGezocht}
          classes={'section__container__columns lightBackground'}
          options={{ deviders: false }}
        >
          <GridBlock>
            <LandGezocht />
          </GridBlock>
        </ColumnBlocks>
        <ColumnBlocks
          title={this.state.diensten.title}
          intro={
            typeof this.state.diensten.main.header.description === 'function' &&
            this.state.diensten.main.header.description(this.props)
          }
          icon={this.state.diensten.icon}
          columns={this.state.diensten}
          classes={'section__container__columns section--clear-diagonal'}
          options={{ colSize: 3 }}
        />

        <Footer />
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { calculateRendement: args => dispatch(calculateRendement(args)) };
};

export default connect(
  state => state,
  mapDispatchToProps
)(HomePage);
